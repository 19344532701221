<template>
    <div class="container-fluid p-4">
        <div class="row row-cols-1 mb-4">
            <div class="col">
                <div class="card">
                    <div class="card-header card-header-divider">Component</div>
                    <div class="card-body text-center">
                        <div role="alert" class="v-toast__item v-toast__item--primary">
                            <div class="v-toast__icon"></div>
                            <p class="v-toast__text">Toast Notification</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row row-cols-1 mb-4">
            <div class="col">
                <div class="card">
                    <div class="card-header card-header-divider">Component</div>
                    <div class="card-body text-left">

                        <div role="alert" class="v-toast__item v-toast__item--transparent shadow-none mr-3">
                            <div class="v-toast__icon"></div>
                            <p class="v-toast__text">Toast Notification</p>
                        </div>

                        <div role="alert" class="v-toast__item v-toast__item--primary">
                            <div class="v-toast__icon"></div>
                            <p class="v-toast__text">Toast Notification</p>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <div class="row row-cols-1">
            <div class="col">
                <div class="card">
                    <div class="card-header card-header-divider">Main Colours</div>
                    <div class="card-body">

                        <div class="row row-cols-1"></div>

                        <div class="col">
                            <div role="alert" class="v-toast__item v-toast__item--success mr-3">
                                <div class="v-toast__icon"></div>
                                <p class="v-toast__text">This is a sample message</p>
                            </div>

                            <div role="alert" class="v-toast__item v-toast__item--danger mr-3">
                                <div class="v-toast__icon"></div>
                                <p class="v-toast__text">This is a sample message</p>
                            </div>

                            <div role="alert" class="v-toast__item v-toast__item--warning mr-3">
                                <div class="v-toast__icon"></div>
                                <p class="v-toast__text">This is a sample message</p>
                            </div>
                        </div>

                        <div class="col">
                            <div role="alert" class="v-toast__item v-toast__item--info mr-3">
                                <div class="v-toast__icon"></div>
                                <p class="v-toast__text">This is a sample message</p>
                            </div>
                            <div role="alert" class="v-toast__item v-toast__item--dark mr-3">
                                <div class="v-toast__icon"></div>
                                <p class="v-toast__text">This is a sample message</p>
                            </div>
                        </div>



                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Alerts',
    mounted() {
        this.$toast.open({
            message: "Toast Notification",
            type: "primary",
            duration: 2500,
            dismissible: true
        })
    }
}
</script>
